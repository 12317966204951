@import "_bootstrap";
@import "_set";


html {

}

* {
  box-sizing: border-box;
}

body {
  font-family: $font-main;
  font-size: 15px;
  line-height: 2.5rem;
}

header {
  //border-bottom: 1px solid rgba(255,255,255,1);
  height: 70px;
  width: 100%;
  position: fixed;
  z-index: 5;

  &.swip {
    background-color: rgba($color-dark,0.5);
    transition: 0.5s all;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
  }

  nav {
    margin: 0 auto;


    ul {
      float: right;
      padding: 2rem 0;



      li {
        display: inline-block;
        list-style: none;
        color: #fff;
        font-weight: 300;
        text-shadow: $shadow;
        font-size: 2rem;
        padding: 5px 10px;
        &:hover {
          background: darken($color-dark, 20%);
          padding: 5px 10px;
          border-radius: 4px;
        }

        a {  color: #fff; text-decoration: none; }
        a:hover {  color: #fff; text-decoration: none; }

      }
    }

  }
}

h2 {
  font-size: 7rem;
  font-weight: 200;
  margin: 10px 0;
}
h3 {
  font-size: 5rem;
  font-weight: 200;
  margin: 10px 0;
}

.bg {
  background-size: contain;
  height: 100vh;
  z-index: 0;
  position: relative;
  animation: slider 12s infinite;

  @keyframes slider
  {
    0%,100% { background: url(../img/dom-02.jpg) no-repeat center center fixed; }
    25% { background: url(../img/salon-05.jpg) no-repeat center center fixed; }
    50% { background: url(../img/dzieci-07.jpg) no-repeat center center fixed; }
    75% { background: url(../img/sypialnie-05.jpg) no-repeat center center fixed; }
  }

  .logo {
    background-image: url(../img/logo-nela.png);
    width: 476px;
    height: 295px;
    z-index: 10;
    display: inline-block;
    position: absolute;
    bottom: 100px;
  }
}

.address {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 3rem;
  text-align: center;
  background-color: $color-dark;
  color: #fff;
  font-size: 2rem;
  font-weight: 300;
  z-index: 5;
}

.desc {
  padding: 50px 20px;
}

.city {
  background-image: url('../img/bg-rowy.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding: 100px 0;

  .desc {
    background: rgba(255,255,255,0.6);
    padding: 20px;
    border-radius: 5px;
  }
}

.gallery {
  padding: 20px;
  h3 {
    padding: 0 20px;
  }
  .thumbnail {
    padding: 0;
  }
}

.form {
  padding: 50px 0;
}

#map {
  width: 100%;
  height: 350px;
  margin-top: 5px;
}

footer {
  background: $color-light;
  text-align: center;
  font-size: 2rem;
  color: $color-dark;
  padding: 30px 0;
}



